<script lang="ts" setup>
import { ref } from "vue";

import RolesGrid from "@/components/iam/RolesGrid.vue";

const addLink = ref({ name: "IAM-RoleAdd", params: { roleId: "-", addModus: true } });
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <roles-grid :addLink="addLink"></roles-grid>
  </v-container>
</template>
